import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import YotpoStars from 'src/components/YotpoStars'
import {
  Section,
  JumbotronImageContainer,
  ContentContainer,
  Headline,
  HeadlineMobile,
  Title,
  Text,
  TextMobile,
  Intro,
  IntroMobile,
  MobileContentContainer,
  CtaContainer,
  MobileTitle,
  CenteredMobile,
  BuyNowLink,
  TimeDisclosure,
  TimeLegal,
  TitleContainer,
  TimeContainer,
} from './styles'
import { CAL_MAX_BUY_LINK } from '../../utils/constants'

const CalMaxHero = ({ frame }) => {
  const { calendarHero, calendarHeroMobile, time } = useStaticQuery(graphql`
    query calMaxHeroQuery {
      calendarHero: file(relativePath: { eq: "calmax/cal-max-hero.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      calendarHeroMobile: file(relativePath: { eq: "calmax/cal-max-hero-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      time: file(relativePath: { eq: "time.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const commonText = {
    intro: 'INTRODUCING CALENDAR MAX',
    mobileIntro: 'Introducing the Skylight Calendar Max',
    title: 'Max<span>imize Your Family Time.</span>',
    text: 'The best-selling smart calendar is now available in a 27" touchscreen display.',
    cta: 'Buy Now',
    productId: '6863425175604',
  }

  return (
    <>
      <Section>
        <JumbotronImageContainer>
          <GatsbyImage
            image={calendarHero.childImageSharp.gatsbyImageData}
            alt="Skylight Calendar Max displayed on wall"
            loading="eager"
          />
          <GatsbyImage
            image={calendarHeroMobile.childImageSharp.gatsbyImageData}
            alt="Skylight Calendar Max displayed on wall"
            loading="eager"
          />
        </JumbotronImageContainer>
        <TitleContainer>
          <TimeContainer mobile>
            <GatsbyImage
              image={time.childImageSharp.gatsbyImageData}
              alt="Time Best Inventions 2024"
              loading="eager"
            />
          </TimeContainer>
          <MobileTitle dangerouslySetInnerHTML={{ __html: commonText.title }} />
        </TitleContainer>
        <ContentContainer>
          <Headline>
            <YotpoStars color="#000" textcolor="#444444" productId={commonText.productId} />
          </Headline>
          <Intro>{commonText.intro}</Intro>
          <TitleContainer>
            <TimeContainer>
              <GatsbyImage
                image={time.childImageSharp.gatsbyImageData}
                alt="Time Best Inventions 2024"
                loading="eager"
              />
            </TimeContainer>
            <Title dangerouslySetInnerHTML={{ __html: commonText.title }} />
          </TitleContainer>
          <Text>{commonText.text}</Text>
          <CtaContainer>
            <CenteredMobile className="desktopCta">
              <BuyNowLink to={CAL_MAX_BUY_LINK}>{commonText.cta}</BuyNowLink>
            </CenteredMobile>
          </CtaContainer>
        </ContentContainer>
        <TimeDisclosure>
          <TimeLegal>
            From TIME. © 2024 TIME USA LLC. <br />
            All rights reserved. Used under license.
          </TimeLegal>
        </TimeDisclosure>
      </Section>
      <MobileContentContainer>
        <HeadlineMobile>
          <YotpoStars color="#000" textcolor="#444444" productId={commonText.productId} />
        </HeadlineMobile>
        <IntroMobile>{commonText.mobileIntro}</IntroMobile>
        <TextMobile>{commonText.text}</TextMobile>
        <CenteredMobile>
          <BuyNowLink to={CAL_MAX_BUY_LINK}>{commonText.cta}</BuyNowLink>
        </CenteredMobile>
      </MobileContentContainer>
    </>
  )
}

CalMaxHero.propTypes = {
  frame: PropTypes.bool,
}

export default CalMaxHero
