export const CAL_MAX_FEATURES = [
  {
    title: `It's our biggest size yet.`,
    text: `You'll never miss a beat with your Calendar Max displaying every detail.`,
  },
  {
    title: 'It fits any space.',
    text: 'The 27" HD display can be mounted in a landscape or portrait orientation, and it adjusts automatically.',
  },
  {
    title: `There's a style for every home.`,
    text: 'Our frame styles and finishes can be easily swapped to match your aesthetic.',
  },
  {
    title: 'The display is impressive and vibrant.',
    text: 'A full HD and anti-glare screen brings some color to your daily schedule and tasks.',
  },
  {
    title: `It's easy to install.`,
    text: 'We give recommended mounting heights and send an installation guide so you can hang your Calendar Max with confidence.',
  },
]

export const FRAME_FEATURES = [
  {
    title: 'Swappable Snap Frames',
    text: `Change up your style in seconds - our magnetic Snap Frames are easy to swap to match your home's decor.`,
  },
  {
    title: 'Stunning Full HD Display',
    text: 'Show off your photos in brilliant HD with vivid colors and crisp details. Smart auto-brightness and the crystal-clear anti-glare screen make your images look perfect in any light, in any room.',
  },
  {
    title: 'More Storage, More Speed',
    text: `There's room for thousands more photos on your Frame 2 with 16 GB of storage. And the incredibly fast, incredibly energy-efficient quad-core processor makes every action feel quick and responsive.`,
  },
  {
    title: 'Bring Your Videos To Life',
    text: `Share videos straight from your phone, just like photos. They'll play in stunning Full HD, on demand - it's the perfect way to feel like you were really there.`,
  },
]
