import React from 'react'

import videoToLife from 'src/videos/videoToLife.mp4'
import moreStorage from 'src/videos/moreStorage.mp4'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import PropTypes from 'prop-types'

import {
  Section,
  Row,
  ContentContainer,
  Header,
  Title,
  Text,
  TextContainer,
  VideoContainer,
  Video,
  ImageContainer,
} from './styles'

import { CAL_MAX_FEATURES, FRAME_FEATURES } from './constants'

const CalendarMaxFeatures = ({ page, cream }) => {
  const {
    dreamBig,
    dreamBigMobile,
    anySpace,
    anySpaceMobile,
    dynamicStyles,
    dynamicStylesMobile,
    vibrantDisplay,
    vibrantDisplayMobile,
    easyInstall,
    easyInstallMobile,
    snapFrameSwap,
    snapFrameSwapMobile,
    frame2Leaf,
    frame2LeafMobile,
  } = useStaticQuery(graphql`
    query CalendarMaxFeaturesQuery {
      dreamBig: file(relativePath: { eq: "calmax/dream-big.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      dreamBigMobile: file(relativePath: { eq: "calmax/dream-big-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      anySpace: file(relativePath: { eq: "calmax/any-space.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      anySpaceMobile: file(relativePath: { eq: "calmax/any-space-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      dynamicStyles: file(relativePath: { eq: "calmax/dynamic-styles.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      dynamicStylesMobile: file(relativePath: { eq: "calmax/dynamic-styles-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      vibrantDisplay: file(relativePath: { eq: "calmax/vibrant-display.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      vibrantDisplayMobile: file(relativePath: { eq: "calmax/vibrant-display-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      easyInstall: file(relativePath: { eq: "calmax/easy-install.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      easyInstallMobile: file(relativePath: { eq: "calmax/easy-install-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      snapFrameSwap: file(relativePath: { eq: "frame2/snap-frame-swap.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      snapFrameSwapMobile: file(relativePath: { eq: "frame2/snap-frame-swap-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      frame2Leaf: file(relativePath: { eq: "frame2/frame-2-leaf.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      frame2LeafMobile: file(relativePath: { eq: "frame2/frame-2-leaf-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const calFeatures = [
    {
      d: dreamBig,
      m: dreamBigMobile,
      alt: '27-inch Skylight Calendar Max digital display showing a detailed family calendar in landscape orientation with tasks and appointments organized by day, highlighting the large screen size for easy viewing',
    },
    {
      d: anySpace,
      m: anySpaceMobile,
      alt: 'Women interacting with a Skylight Calendar Max wall-mounted digital display in portrait mode, showing a family task organizer with customizable sections for each member',
    },
    {
      d: dynamicStyles,
      m: dynamicStylesMobile,
      alt: 'Close-up of four digital display frame styles in black, white, charcoal and shadow box finishes, showcasing swappable frame options to suit different home aesthetics',
    },
    {
      d: vibrantDisplay,
      m: vibrantDisplayMobile,
      alt: 'Side view of a wall-mounted Skylight Calendar Max digital display with a vibrant family schedule, showcasing a full HD, anti-glare screen for clear viewing of daily tasks and events',
    },
    {
      d: easyInstall,
      m: easyInstallMobile,
      alt: 'Women mounting a calendar max on the wall using a wall bracket, highlighting easy installation',
    },
  ]
  const frame2Features = [
    {
      d: snapFrameSwap,
      m: snapFrameSwapMobile,
      alt: 'Hands swapping wooden frames on a digital display skylight frame 2 showing a child and dog photo, highlighting customizable frame options',
    },
    {
      d: frame2Leaf,
      m: frame2LeafMobile,
      alt: 'Close-up of a sleek digital display skylight frame 2 with vibrant green leaves in the background, beside a modern indoor plant in a beige pot',
    },
    { d: '', m: '', video: true, videoSrc: moreStorage },
    {
      d: '',
      m: '',
      video: true,
      videoSrc: videoToLife,
    },
  ]
  const TITLE_OVERRIDE = {
    frame: 'New Features for the New Favorite',
  }
  const FEATURES = page === 'frame' ? FRAME_FEATURES : CAL_MAX_FEATURES
  const features = page === 'frame' ? frame2Features : calFeatures
  return (
    <Section cream={cream}>
      <ContentContainer>
        <Header>{TITLE_OVERRIDE[page] || `Take a Closer Look`}</Header>
        {FEATURES.map((feature, i) => (
          <Row key={i} reverse={i % 2 !== 0}>
            {features[i].gif || features[i].video ? (
              <>
                {features[i].gif ? (
                  <VideoContainer>
                    <img
                      src={features[i].videoSrc}
                      alt="Skylight Calendar turns into a screensaver"
                    />
                  </VideoContainer>
                ) : (
                  <Video playsInline autoPlay muted loop controls={false}>
                    <source src={features[i].videoSrc} type="video/mp4" />
                  </Video>
                )}
              </>
            ) : (
              <>
                <ImageContainer>
                  <GatsbyImage
                    image={features[i].d.childImageSharp.gatsbyImageData}
                    alt={features[i].alt}
                  />
                </ImageContainer>
                <ImageContainer>
                  <GatsbyImage
                    image={features[i].d.childImageSharp.gatsbyImageData}
                    alt={features[i].alt}
                  />
                </ImageContainer>
              </>
            )}
            <TextContainer>
              <Title>{feature.title}</Title>
              <Text>{feature.text}</Text>
            </TextContainer>
          </Row>
        ))}
      </ContentContainer>
    </Section>
  )
}

export default CalendarMaxFeatures

CalendarMaxFeatures.propTypes = {
  page: PropTypes.string,
  cream: PropTypes.bool,
}
