import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const ContentContainer = styled.div`
  width: 90%;
  margin: auto;
  max-width: 1050px;
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const Header = styled.h1`
  font-family: 'FilsonPro';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: black;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 24px;
    margin: 24px;
  }
`
export const Title = styled.p`
  font-family: 'FilsonProBold';
  font-size: 24px;
  font-weight: 500;
  margin: 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`
export const Text = styled.p`
  font-family: 'FilsonProBook';
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 8px 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 22px;
  }
`
export const MobileImageContainer = styled.object`
  @media (max-width: ${breakpoints.m}px) {
    display: block;
    width: 80%;
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
  }
`
export const Row = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 16px;
  padding: 50px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  object:nth-child(2) {
    display: none;
  }
  @media (max-width: ${breakpoints.l}px) {
    padding: 50px;
    flex-direction: column;
  }
  @media (max-width: ${breakpoints.m}px) {
    object:nth-child(1) {
      display: none;
    }
    object:nth-child(2) {
      display: block;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 20px 0px;
    border-radius: 0px;
  }
`
export const TextContainer = styled.div`
  margin-top: 20px;
  width: 400px;
  padding: 20px;
  @media (max-width: ${breakpoints.s}px) {
    max-width: 90%;
    margin: ${(props) => (props.extra ? '30px auto 10px' : '15px auto 10px')};
    padding: 0px;
  }
`
