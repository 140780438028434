import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Header = styled.h1`
  font-size: 38px;
  line-height: 53px;
  letter-spacing: 0.19px;
  font-family: 'P22MackinacProBook';
  text-align: center;
  max-width: 90%;
  margin: 0px auto 20px;
  @media (max-width: 1100px) {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.15px;
  }
`
export const Section = styled.div`
  margin: 75px 0px;
  @media (max-width: ${breakpoints.s}px) {
    margin: 40px 0px;
  }
`
